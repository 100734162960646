.logo {
  max-width: 256px;
  margin: 0 auto;
}
button.buttonChoice,
a.buttonChoice {
  height: 64px;
  background-color: #0032a1;
  color: white;
  border-radius: 12px;
  &:hover {
    color: white;
    border: 1px solid white;
  }
}