.errorMessage {
  margin: 0.7rem 0 0 0;
}
.socialButtons {
  flex-direction: column;
}
.icon {
  margin-right: 5px;
}
.logo {
  max-width: 256px;
  margin: 0 auto;
}
.buttonDisabled {
  pointer-events: none;
  cursor: pointer;
}